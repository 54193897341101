import { Layout } from 'antd';
import FooterComponent from '@components/templates/FooterComponent';
import SidebarComponent from '@components/templates/SidebarComponent';
import HeaderComponent from '@components/templates/HeaderComponent';
import router from 'next/router';

const { Content } = Layout;

export const MainContentComponent: React.FC = ({ children }): any => {
  return (
    <Layout>
      <HeaderComponent />
      <Layout hasSider>
        <SidebarComponent />
        <Layout>
          <Content
            className={`site-layout-background ${
              router.route === '/' ? 'not-inline-border' : 'yes-inline-border'
            }`}
          >
            {children}
          </Content>
          <FooterComponent />
        </Layout>
      </Layout>
    </Layout>
  );
};

export default MainContentComponent;
