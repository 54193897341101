import Router from 'next/router';
import { RootStateOrAny } from 'react-redux';
import { Layout, Spin } from 'antd';
import { useAppSelector } from '@app/hooks';

import session from '@utils/session';
import { protectedRoutes } from '@utils/protectedRoutes';

const BasicLayout: React.FC | any = ({ children, roles }: any) => {
  const { user } = session({ redirectTo: '/auth/log-in' });
  const { user: userState } = useAppSelector((state: RootStateOrAny) => state.auth ?? {});

  const spinner = (
    <Layout
      style={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Spin />
    </Layout>
  );

  if (
    protectedRoutes.some((route) => {
      return (
        Router.pathname === route.path &&
        !userState.roles?.some((role) => route.accessRoles.includes(role))
      );
    })
  ) {
    Router.push('/');
    return spinner;
  }

  // THIS IS A EXAMPLE THE LOADING
  if (!user || user.isLoggedIn === false || !userState) {
    return spinner;
  }

  return <>{children}</>;
};

export default BasicLayout;
