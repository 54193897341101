import { useRouter } from 'next/router';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useAppSelector } from '@app/hooks';
import i18n from 'i18next';

import Icon from '@mdi/react';
import {
  mdiHomeOutline,
  mdiFileDocumentOutline,
  mdiSchoolOutline,
  mdiAccountOutline,
  mdiBriefcaseOutline,
  mdiStoreOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiCarOutline,
  mdiChartBoxOutline,
  mdiBellOutline,
  mdiClipboardTextOutline,
} from '@mdi/js';

import { Affix, Layout, Menu } from 'antd';
import { validateAccess } from '@utils/functions';
import { CountryEnum, StaffRoles } from '@utils/enum';

const { Sider } = Layout;

const { Item } = Menu;

export const SidebarComponent = (): JSX.Element => {
  const router = useRouter();
  const { countryIso } = useSelector((state: RootStateOrAny) => state.auth?.user ?? {});
  const { roles } = useAppSelector((state: RootStateOrAny) => state.auth?.user);

  return (
    <Sider className="sidebar-back">
      <Affix offsetTop={65}>
        <div
          style={{
            height: '100vh',
            overflow: 'auto',
            paddingBottom: '90px',
          }}
        >
          <Menu
            onClick={({ key }: any) => router.push(key)}
            mode="vertical"
            defaultSelectedKeys={[router.pathname]}
          >
            <Item key="/" data-cy="home" icon={<Icon path={mdiHomeOutline} size={1} />}>
              {i18n.t('sidebar.menu.home')}
            </Item>

            <Item
              key="/dashboard"
              data-cy="dashboard"
              icon={<Icon path={mdiChartBoxOutline} size={1} />}
            >
              {i18n.t('sidebar.menu.dashboard')}
            </Item>

            <Menu.SubMenu
              className="sidebar-menu"
              key="jobbers"
              data-cy="jobbers"
              title={i18n.t('sidebar.menu.jobbers.title')}
              icon={<Icon path={mdiAccountOutline} size={1} />}
            >
              <Item className="sidebar-back" data-cy="jobbers-applicants" key="/applicants">
                {i18n.t('sidebar.menu.jobbers.submenu.0')}
              </Item>
              <Item className="sidebar-back" data-cy="jobbers-jobbers" key="/jobbers">
                {i18n.t('sidebar.menu.jobbers.submenu.1')}
              </Item>

              <Item className="sidebar-back" data-cy="blocklist-safelist" key="/blocklist-safelist">
                {i18n.t('sidebar.menu.jobbers.submenu.2')}
              </Item>
            </Menu.SubMenu>

            {validateAccess(roles, [StaffRoles.STAFF_MARKETING]) && (
              <Item
                key="/notifications"
                data-cy="notifications"
                icon={<Icon path={mdiBellOutline} size={1} />}
              >
                {i18n.t('sidebar.menu.notification.title')}
              </Item>
            )}

            <Item
              key="/courses-skills-shifts"
              data-cy="courses-skills-shifts"
              icon={<Icon path={mdiSchoolOutline} size={1} />}
            >
              {i18n.t('sidebar.menu.courses.title')}
            </Item>
            <Item key="/tasks" data-cy="tasks" icon={<Icon path={mdiBriefcaseOutline} size={1} />}>
              {i18n.t('sidebar.menu.task.title')}
            </Item>

            <Item key="/delivery" data-cy="delivery" icon={<Icon path={mdiCarOutline} size={1} />}>
              {i18n.t('sidebar.menu.delivery.title')}
            </Item>

            {validateAccess(roles, [StaffRoles.STAFF_FINANCE]) && countryIso !== CountryEnum.CL && (
              <Item
                key="/payments-labours"
                data-cy="payments"
                icon={<Icon path={mdiCurrencyUsd} size={1} />}
              >
                {i18n.t('sidebar.menu.payments.title')}
              </Item>
            )}
            {validateAccess(roles, [StaffRoles.STAFF_FINANCE]) && countryIso === CountryEnum.CL && (
              <Menu.SubMenu
                className="sidebar-menu"
                key="/payments"
                data-cy="payments"
                icon={<Icon path={mdiCurrencyUsd} size={1} />}
                title={i18n.t('sidebar.menu.payments.title')}
              >
                <Item className="sidebar-back" data-cy="payments-labours" key="/payments-labours">
                  {i18n.t('sidebar.menu.payments.submenu.0')}
                </Item>
                {countryIso === CountryEnum.CL && (
                  <Item
                    className="sidebar-back"
                    data-cy="payments-shoppers"
                    key="/payments-shoppers"
                  >
                    {i18n.t('sidebar.menu.payments.submenu.1')}
                  </Item>
                )}
              </Menu.SubMenu>
            )}

            {process.env.NEXT_PUBLIC_ENVIRONMENT !== 'production' && (
              <Item
                key="/requirements"
                data-cy="requirements"
                icon={<Icon path={mdiClipboardTextOutline} size={1} />}
              >
                {i18n.t('sidebar.menu.requirements.title')}
              </Item>
            )}

            <Item
              key="/finance/report"
              data-cy="finance-report"
              icon={<Icon path={mdiFileDocumentOutline} size={1} />}
            >
              {i18n.t('sidebar.menu.finance.title')}
            </Item>

            <Item
              key="/enterprises"
              data-cy="enterprises"
              icon={<Icon path={mdiStoreOutline} size={1} />}
            >
              {i18n.t('sidebar.menu.enterprise.title')}
            </Item>

            <Menu.SubMenu
              className="sidebar-menu"
              key="/settings"
              data-cy="settings"
              title={i18n.t('sidebar.menu.settings.title')}
              icon={<Icon path={mdiCogOutline} size={1} />}
            >
              {validateAccess(roles, [StaffRoles.ADMIN, StaffRoles.STAFF]) && (
                <>
                  <Item className="sidebar-back" data-cy="settings-staffs" key="/staffs">
                    {i18n.t('sidebar.menu.settings.submenu.0')}
                  </Item>
                  <Item className="sidebar-back" data-cy="settings-localities" key="/localities">
                    {i18n.t('sidebar.menu.settings.submenu.1')}
                  </Item>
                </>
              )}
              <Item
                className="sidebar-back"
                data-cy="settings-jobTypes"
                key="/maintainers/job-types"
              >
                {i18n.t('sidebar.menu.settings.submenu.2')}
              </Item>
              <Item
                className="sidebar-back"
                data-cy="settings-coordinators"
                key="/maintainers/coordinators"
              >
                {i18n.t('sidebar.menu.settings.submenu.3')}
              </Item>
            </Menu.SubMenu>
          </Menu>
        </div>
      </Affix>
    </Sider>
  );
};

export default SidebarComponent;
