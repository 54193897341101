import { useEffect } from 'react';
import i18n from 'i18next';
import { useRouter } from 'next/router';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import Gravatar from 'react-gravatar';

import { Row, Col, Dropdown, Layout, Menu, Avatar, Typography, Affix } from 'antd';
import Icon from '@mdi/react';
import { mdiChevronDown } from '@mdi/js';

import { clearLogout, logout } from '@features/auth/authSlice';

import LocalStorageService from '@utils/localStorage';

const { Header } = Layout;
const { Text } = Typography;

const localStorageService = LocalStorageService.getService();

export const HeaderComponent = (): JSX.Element => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { loggingOut } = useSelector((state: RootStateOrAny) => state.auth);
  const { names, email } = useSelector((state: RootStateOrAny) => state.auth?.user);

  const menuOptions = (
    <Menu>
      <Menu.Item key="0">
        <span onClick={() => dispatch(logout())}>{i18n.t('global.logout')}</span>
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    if (loggingOut) {
      localStorageService.clearOnLogout();
      router.push('/auth/log-in');
      return () => {
        dispatch(clearLogout());
      };
    }
  }, [loggingOut]);

  return router.route === '/tasks/bulk' ? (
    <Header className="site-layout-background navbar">
      <Row gutter={[16, 16]} className="header-ini">
        <Col span={12}>
          <img width={150} src={'/tj-logo.svg'} />
        </Col>
        <Col className="text-right" span={12}>
          <Gravatar email={email} size={28} style={{ borderRadius: '50%' }} />

          <Text strong>{names?.toUpperCase()}</Text>
          <Dropdown overlay={menuOptions} placement="bottomCenter" arrow>
            <Avatar icon={<Icon size="30px" color="#324B5E" path={mdiChevronDown} />} />
          </Dropdown>
        </Col>
      </Row>
    </Header>
  ) : (
    <Affix offsetTop={0}>
      <Header className="site-layout-background navbar">
        <Row gutter={[16, 16]} className="header-ini">
          <Col span={12}>
            <img width={150} src={'/tj-logo.svg'} />
          </Col>
          <Col className="text-right" span={12}>
            <Gravatar email={email} size={28} style={{ borderRadius: '50%' }} />

            <Text strong>{names?.toUpperCase()}</Text>
            <Dropdown overlay={menuOptions} placement="bottomCenter" arrow>
              <Avatar icon={<Icon size="30px" color="#324B5E" path={mdiChevronDown} />} />
            </Dropdown>
          </Col>
        </Row>
      </Header>
    </Affix>
  );
};

export default HeaderComponent;
