import { RolesEnum } from '../features/auth/ts/enums';

export const protectedRoutes = [
  {
    path: '/payments',
    accessRoles: [RolesEnum.STAFF_FINANCE],
  },
  {
    path: '/staffs',
    accessRoles: [RolesEnum.ADMIN, RolesEnum.STAFF],
  },
  {
    path: '/localities',
    accessRoles: [RolesEnum.ADMIN, RolesEnum.STAFF],
  },
  {
    path: '/notifications',
    accessRoles: [RolesEnum.STAFF_MARKETING],
  },
];
