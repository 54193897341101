import moment from 'moment';
import { Layout } from 'antd';

const { Footer } = Layout;

export const FooterComponent = (): JSX.Element => (
  <Footer style={{ textAlign: 'center' }}>
    {`Time Jobs ©${moment().year()}`}
  </Footer>
);

export default FooterComponent;
